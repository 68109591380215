import React, { useEffect } from "react";
import "./location.css";
import location from "../../media/location.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Location() {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation("home");

  return (
    <div className="border-y border-default mt-20 md:mt-10 ml-8 md:ml-44 mr-8 md:mr-44">
      <h1
        data-aos="fade-up"
        data-aos-duration="1000"
        className="headL text-center mt-10 md:mt-10 mb-10 "
      >
      {t("Location.title")}
      </h1>
      <div className=" md:grid grid-cols-2 mt-10 md:mt-10 mb-10 md:mb-20">
        <div
          data-aos="fade-right"
          data-aos-duration="1200"
          className="relative"
        >
          <img src={location} alt="location" className="Limage" />
          <a className="" href="https://maps.app.goo.gl/GUjkhNjmeRjq9vmM6" target={"_blank"}>
            <div className="absolute -bottom-10 -right-2 md:-right-14 z-20">
              <div className="relative hover:scale-105">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-20 md:w-96 h-40 "
                  viewBox="0 0 240 242"
                  fill="none"
                >
                  <path
                    d="M238.5 121C238.5 187.01 185.434 240.5 120 240.5C54.566 240.5 1.5 187.01 1.5 121C1.5 54.9902 54.566 1.5 120 1.5C185.434 1.5 238.5 54.9902 238.5 121Z"
                    fill="#170F14"
                    stroke="#C1A875"
                    strokeWidth="2"
                  />
                </svg>
                <h1 className="absolute  inset-0 flex items-center justify-center text-white text-xs md:text-lg">
                {t("Location.button")}                </h1>
              </div>
            </div>
          </a>
        </div>

        <div data-aos="fade-left" data-aos-duration="1000">
          <h1 className="Lpara text-left mt-14 md:mt-6 my-4">
          {t("Location.text")}
          </h1>
          {/* <h1 className="Lpara text-center my-4">Lorem Ipsum. Skopje 16</h1> */}
        </div>
      </div>
    </div>
  );
}
export default Location;
