import React, { useState, useEffect } from "react";
import "./gallery.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

//Type A
import A1 from "../../media/typeA/A1.jpg";
import A2 from "../../media/typeA/A2.jpg";
import A3 from "../../media/typeA/A3.jpg";
import A4 from "../../media/typeA/A4.jpg";
import A5 from "../../media/typeA/A5.jpg";
import A6 from "../../media/typeA/A6.jpg";
import A7 from "../../media/typeA/A7.jpg";
import A8 from "../../media/typeA/A8.jpg";
//Type B
import B1 from "../../media/typeB/B1.jpg";
import B2 from "../../media/typeB/B2.jpg";
import B3 from "../../media/typeB/B3.jpg";
import B4 from "../../media/typeB/B4.jpg";
import B5 from "../../media/typeB/B5.jpg";
import B6 from "../../media/typeB/B6.jpg";
//Typce C
import C1 from "../../media/typeC/C1.jpg";
import C2 from "../../media/typeC/C2.jpg";
import C3 from "../../media/typeC/C3.jpg";
import C4 from "../../media/typeC/C4.jpg";
import C5 from "../../media/typeC/C5.jpg";
import C6 from "../../media/typeC/C6.jpg";
import back from "../../media/back.svg";
import next from "../../media/next.svg";
// ...

function Gallery() {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation("home");

  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [
    { src: A1, name: "Apartment", type: "Type A" },
    { src: A2, name: "Apartment", type: "Type A" },
    { src: A3, name: "Apartment", type: "Type A" },
    { src: A4, name: "Apartment", type: "Type A" },
    { src: A5, name: "Apartment", type: "Type A" },
    { src: A6, name: "Apartment", type: "Type A" },
    // { src: A7, name: "Apartment", type: "Type A" },
    { src: A8, name: "Apartment", type: "Type A" },
    //Type B
    { src: B1, name: "Apartment", type: "Type B" },
    { src: B2, name: "Apartment", type: "Type B" },
    { src: B3, name: "Apartment", type: "Type B" },
    { src: B4, name: "Apartment", type: "Type B" },
    { src: B5, name: "Apartment", type: "Type B" },
    { src: B6, name: "Apartment", type: "Type B" },
    //Type C
    { src: C1, name: "Apartment", type: "Type C" },
    { src: C2, name: "Apartment", type: "Type C" },
    { src: C3, name: "Apartment", type: "Type C" },
    { src: C4, name: "Apartment", type: "Type C" },
    { src: C5, name: "Apartment", type: "Type C" },
    { src: C6, name: "Apartment", type: "Type C" },
    // Add more images as needed
  ];

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  return (
    <div className="mb-8  md:mb-8 mt-36 md:mt-32">
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="flex items-center justify-center md:h-96 "
      >
        <div className="relative w-full h-full md:mb-10">
          <div className="absolute -inset-1  flex items-center justify-center">
            <div className="w-4/5 md:w-1/2 mr-2 md:mr-8 mt-24 bg-gallery bg-opacity-30  previmg">
              <img
                src={
                  images[(currentIndex - 1 + images.length) % images.length].src
                }
                alt="prevImage"
                className="previmg "
              />
            </div>
            <div className="">
              <img
                src={images[currentIndex].src}
                alt="currentImage"
                className="cimg "
              />
            </div>
            <div className="w-4/5 md:w-1/2  mt-24 ml-2 md:ml-8 bg-gallery bg-opacity-30 nextimg">
              <img
                src={images[(currentIndex + 1) % images.length].src}
                alt="nextImage"
                className="previmg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center mt-24 ">
        <div className="flex items-center justify-center">
          <div className="grid grid-rows-2 -ml-4 md:-ml-20 mr-4 md:mr-20">
            <div className="flex justify-start border-b-2 border-defaultOP w-16 md:w-48 ">
              <h1 className="gltx1 ">
                {currentIndex === 0
                  ? t(`gallery.types.${images[images.length - 1].type}`)
                  : t(`gallery.types.${images[currentIndex - 1].type}`)}
              </h1>
            </div>
            <div className="flex justify-start">
              <h1 className="gltx2  ">
                {currentIndex === 0
                  ? t(`gallery.names.${images[images.length - 1].name}`)
                  : t(`gallery.names.${images[currentIndex - 1].name}`)}
              </h1>
            </div>
          </div>
          <button className="eclipseB mr-4 md:mr-28" onClick={goToPrevious}>
            <img src={back} className="w-4 md:w-12 h-4 md:h-12 mx-auto" />
          </button>
        </div>
        <div className="eclipseG mt-10 md:mt-0 mb-5 flex flex-col items-center justify-center">
          <span className="gtext1">
            {t(`gallery.types.${images[currentIndex].type}`)}
          </span>
          <span className="gtext2 mt-4">
            {t(`gallery.names.${images[currentIndex].name}`)}
          </span>
        </div>
        <div className="flex items-center justify-center">
          <button className="eclipseB ml-4 md:ml-28" onClick={goToNext}>
            <img src={next} className="w-4 md:w-12 h-4 md:h-12 mx-auto" />
          </button>
          <div className="grid grid-rows-2 -mr-4 md:-mr-20 ml-4 md:ml-20">
            <div className="flex justify-start border-b-2 border-defaultOP w-16 md:w-44 ">
              <h1 className="gltx1 ">
                {currentIndex === images.length - 1
                  ? t(`gallery.types.${images[0].type}`) // Display the first image's type if it's the last image
                  : t(
                      `gallery.types.${
                        images[(currentIndex + 1) % images.length].type
                      }`
                    )}
              </h1>
            </div>
            <div className="flex justify-start">
              <h1 className="gltx2   ">
                {currentIndex === images.length - 1
                  ? t(`gallery.names.${images[0].name}`)
                  : t(`gallery.names.${images[currentIndex + 1].name}`)}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
