import React, { useEffect } from "react";
import Agallery from "./Agallery";
import "./about.css";
import about1 from "../../media/aboutus1.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Aboutus() {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation("about");

  return (
    <div className=" bg-background">
      <div className="mt-0 md:mt-10 mb-10 md:mb-20 ">
        <div className=" ml-8 md:ml-44 mr-8 md:mr-44">
          <h1 className="headFe text-left mt-10 md:mt-10 mb-10 md:mb-10 border-b-2 border-defaultOP">
            {t("about.title")}
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-duration="1200"
            className="titleFe text-center md:mt-10 "
          >
            {t("about.subTitle")}
          </h1>
        </div>
        <div className=" md:grid grid-cols-2 ml-8 md:ml-44 mr-8 md:mr-44">
          <div className=" col-span-1">
            <div
              data-aos="fade-right"
              data-aos-duration="1200"
              className="mt-5 md:mt-20"
            >
              <div className=" mx-auto ">
                <h1 className="aHead mb-2 md:mb-6 "> {t("about.title1")}</h1>
                <p className="aPara md:mr-16 mb-4 md:mb-0">{t("about.text")}</p>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1200"
            className=" col-span-1 mt-0 md:mt-20"
          >
            <img src={about1} alt="about" className="w-full mb-0 md:mb-8 " />
          </div>
        </div>
      </div>
      <h1 className="titleFe text-center mt-2 md:mt-10 ">
        {" "}
        {t("about.title2")}
      </h1>
      <h1 className="paraFe text-center mt-3 md:mt-6 mb-10 md:mb-10 ">
        {t("about.title3")}
      </h1>
      <Agallery />
      <div className="border-b border-default mr-8 md:mr-44 ml-8 md:ml-44"></div>
    </div>
  );
}
export default Aboutus;
