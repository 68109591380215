/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from "react";
import { Twirl as Hamburger } from "hamburger-react";
import { useTranslation } from "react-i18next"; // Import useTranslation
import logo from "../../media/logo.png";
import phone from "../../media/phone.svg";
import mail from "../../media/mail.svg";
import fb from "../../media/fb.svg";
import insta from "../../media/insta.svg";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState('en');

  const { i18n } = useTranslation(); // Use useTranslation hook
  const { t } = useTranslation("contact");

  const getCurrentPath = () => {
    const path = window.location.pathname;
    return path.endsWith("/") ? path.slice(0, -1) : path;
  };
  const currentPath = getCurrentPath();

  const navbarRef = useRef(null);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setActiveLanguage(lang);

  };

  // Close the menu when clicked outside
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        isMenuOpen &&
        navbarRef.current &&
        !navbarRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isMenuOpen]);

  // Make the mobile version navbar sticky
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerWidth <= 768) {
        if (window.scrollY > 0) {
          navbarRef.current.classList.add("sticky");
        } else {
          navbarRef.current.classList.remove("sticky");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="ml-0 md:ml-44 mr-0 md:mr-44">
      <nav
        ref={navbarRef}
        className={`bg-background md:bg-transparent pt-6 ${
          isMenuOpen ? "open-menu" : ""
        }`}
      >
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto sm:p-4 md:p-0 border-b border-default">
          <a href="/" className="md:flex items-center hover:scale-105">
            <img
              src={logo}
              className="h-20 md:h-32 mr-3 ml-4 md:ml-0"
              alt=" Deluxe Logo"
            />
          </a>
          <div className="flex justify-between  md:order-2">
            <div className="flex items-center space-x-3 mr-4">
              <button
                className={`text-default text-lg hover:text-defaultOP ${
                  activeLanguage === "en" ? "" : ""
                }`}
                onClick={() => changeLanguage("en")}
              >
                EN
              </button>
              <button
                className={`text-default text-lg hover:text-defaultOP ${
                  activeLanguage === "mk" ? "" : ""
                }`}
                onClick={() => changeLanguage("mk")}
              >
                MK
              </button>
            </div>
            <a href="tel:+38976420420" className="hover:scale-110">
              <img src={phone} className="w-6 h-6 mx-2" alt="phone" />
            </a>{" "}
            <a href="mailto:Info@deluxe-home.mk" className="hover:scale-110">
              <img src={mail} className="w-6 h-6 mx-2" alt="mail" />
            </a>{" "}
            <a
              href="https://www.facebook.com/profile.php?id=100063644886591&mibextid=7cd5pb"
              className="hover:scale-110"
              target="_blank"
            >
              <img src={fb} className="w-6 h-6 mx-2" alt="fb" />
            </a>{" "}
            <a
              href="https://www.instagram.com/deluxehome.mk/"
              target="_blank"
              className="hover:scale-110"
            >
              <img src={insta} className="w-6 h-6 mx-2 " alt="instagram" />
            </a>{" "}
            <div className="md:hidden sm:block -mt-3 w-9 h-8 mr-4">
              <Hamburger
                label="Show menu"
                easing="ease-in"
                toggled={isMenuOpen}
                toggle={setIsMenuOpen}
                distance="m"
                color="#C1A875"
                duration={0.4}
              ></Hamburger>
            </div>
          </div>
          <div
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${
              isMenuOpen ? "block " : "hidden"
            }`}
          >
            <ul className="flex flex-col  text-lg uppercase p-4 md:p-0 mt-4 mb-2 border border-default  md:flex-row md:space-x-8 md:mt-0 md:border-0">
              <li>
                <a
                  href="/"
                  className={`block py-2 pl-3 pr-4 rounded md:hover:bg-transparent text-default md:p-0  hover:text-defaultOP ${
                    currentPath === ""
                      ? " md:border-b border-default rounded-none bg-defaultOP md:bg-transparent text-default "
                      : ""
                  }`}
                >
                  {t("contact.nav1")}
                </a>
              </li>
              <li>
                <a
                  href="/features"
                  className={`block py-2 pl-3 pr-4 rounded md:hover:bg-transparent text-default md:p-0 hover:text-defaultOP  ${
                    currentPath === "/features"
                      ? " md:border-b border-default rounded-none bg-defaultOP md:bg-transparent text-default "
                      : ""
                  }`}
                >
                  {t("contact.nav2")}
                </a>
              </li>

              <li>
                <a
                  href="/about"
                  className={`block py-2 pl-3 pr-4 rounded md:hover:bg-transparent text-default md:p-0 hover:text-defaultOP  ${
                    currentPath === "/about"
                      ? " md:border-b border-default rounded-none bg-defaultOP md:bg-transparent text-default "
                      : ""
                  }`}
                >
                  {" "}
                  {t("contact.nav3")}
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  className={`block py-2 pl-3 pr-4 rounded md:hover:bg-transparent text-default md:p-0 hover:text-defaultOP ${
                    currentPath === "/contact"
                      ? " md:border-b border-default rounded-none bg-defaultOP md:bg-transparent text-default "
                      : ""
                  }`}
                >
                  {" "}
                  {t("contact.nav4")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
