import React from "react";
import Navbar from "../home/Navbar";
import Aboutus from "./Aboutus";
import Footer from "../Footer";
function About() {
  return (
    <div className=" bg-background">
      <Navbar />
      <Aboutus />
      <Footer />
    </div>
  );
}
export default About;
