import {React, useEffect, useState} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom"; // Import BrowserRouter
import Home from "./components/home/Home";
import Features from "./components/features/Features";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import { useTranslation } from "react-i18next"; // Import useTranslation

import axios from 'axios'
function App() {

  const { i18n } = useTranslation(); // Use useTranslation hook
  const [location,setLocation] = useState()
  const fetchData = async () => {
    try {
      const response = await axios.get("https://api.ipgeolocation.io/ipgeo?apiKey=390b4b91f538428690fb86242407e0b2");
      setLocation(response.data);
      
      if (response.data && response.data.country_code2 === 'MK') {
        i18n.changeLanguage('mk');
      } else {
        i18n.changeLanguage('en');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        {/* Wrap your Routes with BrowserRouter */}
        <div className="h-full w-full overflow-x-hidden bg-background">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/features" element={<Features />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
      </BrowserRouter>
    </I18nextProvider>
  );
}

export default App;
