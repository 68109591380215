// Hero.js

import React, { useEffect } from "react";
import Navbar from "./Navbar"; // Path to your Navbar component
import "./hero.css"; // You can keep this if needed
import hero from "../../media/hero.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from 'react-i18next';

function Hero() {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation('home');

  return (
    <>
      <div className="sm:hidden">
        <Navbar className=" navbar-container" />
      </div>

      <div className="  relative z-10 overflow-y-hidden overflow-x-hidden  mb-10 md:mb-20  ">
        {/* Background image with reduced opacity */}
        <div className="absolute inset-0 -z-10 hero-image">
          <img src={hero} alt="Hero Image" className=" heroIMG h-full w-full object-center"/>
        </div>
        <div className="sm:block hidden">
          {/* Navbar */}
          <Navbar className="  navbar-container" />
        </div>
        {/* Content with text */}
        <div className="relative z-10">
          <div className="text-hero ">
            <h1
              data-aos="fade-up"
              data-aos-duration="1200"
              className="hero-text font-monsterrat">
             {t('hero.welcome')}
            </h1>
            <p
              data-aos="fade-left"
              data-aos-duration="1600"
              className="hero-p  pl-4 md:pl-0 pr-4 md:pr-12 pb-14">
             {t('hero.description')}
            </p>
          </div>
          {/* <div className=" flex flex-itmes justify-center mt-4 md:mt-0 ml-72 md:ml-32 pb-0 md:pb-6">
            <img src={eclipse} alt="Hero Image" className="h-10 md:h-auto" />
            <img
              src={poly}
              alt="Hero Image"
              className=" mt-8 md:mt-20 -ml-4 md:-ml-6 h-4 md:h-auto"
            />
            <div className="md:mb-6">
              <p className="hero-p1 mt-0 md:mt-8 ml-4 ">EXPLORE 3D</p>
              <p className="hero-p1 ml-4 -mt-3 md:-mt-0 ">ENVIROMENT </p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Hero;
