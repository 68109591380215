/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../media/logo.svg";
import "./footer.css";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation("contact");

  return (
    <div className="font-monsterrat">
      <footer className="bg-background mr-8 md:mr-44 ml-8 md:ml-44">
        <div className="mx-auto w-full max-w-screen-xl mt-4 md:mt-5   py-0 ">
          <div className=" flex justify-between ">
            <div className="mb-0">
              <a href="#" className="flex items-center">
                <img
                  src={logo}
                  className="mr-3 mt-5 md:mt-5"
                  alt="Deluxe Logo"
                />
              </a>
            </div>
            <div className="mb-0  md:ml-12">
              <h2 className="mb-4 titleF text-default"> {t("contact.nav")}</h2>
              <ul className="linkF">
                <li className="mb-1">
                  <a href="#" className="hover:underline">
                    {t("contact.nav1")}
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/features" className="hover:underline">
                  {t("contact.nav2")}
                  </a>
                </li>
                <li className="mb-1">
                  <a href="/about" className="hover:underline">
                  {t("contact.nav3")}
                  </a>
                </li>
                <li className="">
                  <a href="/contact" className="hover:underline">
                  {t("contact.nav4")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-center border-t border-default mt-4">
          <p className="mt-2 text-defaultOP md:text-base text-sm md:pr-0 pr-12 md:pl-0 pl-12 text-center">
            {t("contact.footer1")}
            <span className="text-default font-bold">
              {" "}
              {t("contact.footer2")}
            </span>{" "}
            {t("contact.footer3")}
            <div className=" inline-block">
              <a
                href="https://checkmate.mk/"
                target="{_blank}"
                className="text-default text-lg font-bold hover:text-bgop"
              >
                Checkmate
              </a>
            </div>
            .
          </p>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
