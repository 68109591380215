import React, { useEffect } from "react";
import "./special.css";
import smart2 from "../../media/smart2.png";
import ytong from "../../media/ytong.png";
import dapt1 from "../../media/dapt1.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Special() {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation("features");

  return (
    <div className=" bg-background">
      <div className=" mt-8 md:mt-10  ">
        <div className="border-b border-default  ml-8 md:ml-44 mr-8 md:mr-44">
          <h1 className="headFe text-left mt-10 md:mt-10 mb-10 border-b-2 border-defaultOP">
            {t("features.title")}
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-duration="1200"
            className="titleFe text-center mt-10 md:mt-10 "
          >
            {t("features.subTitle")}
          </h1>
          <h1
            data-aos="fade-up"
            data-aos-duration="1200"
            className="paraFe text-center mt-2 md:mt-10 mb-5 md:mb-10 "
          >
            {t("features.advantages")}
          </h1>
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="hidden md:block border border-default w-full h-full mt-10 mb-28 "
          >
            <div className="grid grid-cols-3">
              <div
                className=" bg-default rotate-180 w-1/3  flex items-center justify-center col-span-1"
                style={{ writingMode: "vertical-rl" }}
              >
                <h1 className="dText">{t("features.title1")}</h1>
              </div>
              <div className="grid grid-cols-2 -ml-52">
                <p className="FHead mt-3 mb-8 ">
                  {t("WiFiSmartDoorLockS2.Heading")}
                  <ul className="mt-2 dParaF">
                    <li className="my-1  list-disc">
                      {t("WiFiSmartDoorLockS2.li1")}
                    </li>
                    <li className="my-1  list-disc">
                      {" "}
                      {t("WiFiSmartDoorLockS2.li2")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("WiFiSmartDoorLockS2.li3")}
                    </li>
                    <li className=" my-1 list-disc">
                      {t("WiFiSmartDoorLockS2.li4")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("WiFiSmartDoorLockS2.li5")}
                    </li>
                  </ul>
                </p>
                <p className="FHead mt-3  ml-20 ">
                  {t("MixSwitchSingleLive.Heading")}
                  <ul className="mt-2 dParaF">
                    <li className=" my-1 list-disc">
                      {" "}
                      {t("MixSwitchSingleLive.li1")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("MixSwitchSingleLive.li2")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("MixSwitchSingleLive.li3")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("MixSwitchSingleLive.li4")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("MixSwitchSingleLive.li5")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("MixSwitchSingleLive.li6")}
                    </li>
                  </ul>
                </p>
                <p className="FHead mt-2 ">
                  {t("ZigbeeMiniHubVS20ZW.Heading")}
                  <ul className="mt-2 dParaF">
                    <li className=" my-1 list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li1")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li2")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li3")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li4")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li5")}
                    </li>
                  </ul>
                </p>
                <p className="FHead mt-2 ml-20  ">
                  {t("MixPadMiniAllinone.Heading")}
                  <ul className="mt-2 dParaF">
                    <li className="my-1  list-disc">
                      {" "}
                      {t("MixPadMiniAllinone.li1")}
                    </li>
                    <li className=" my-1 list-disc">
                      {t("MixPadMiniAllinone.li2")}
                    </li>
                    <li className=" my-1 list-disc">
                      {t("MixPadMiniAllinone.li3")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("MixPadMiniAllinone.li4")}
                    </li>
                  </ul>
                </p>

                <p className="FHead -mt-4   ">
                  {t("SmokeSensor.Heading")}
                  <ul className="mt-2 dParaF">
                    <li className=" my-1 list-disc">{t("SmokeSensor.li1")}</li>
                    <li className="my-1  list-disc">{t("SmokeSensor.li2")}</li>
                    <li className="my-1  list-disc">{t("SmokeSensor.li3")}</li>
                    <li className="my-1  list-disc">{t("SmokeSensor.li4")}</li>
                    <li className="my-1  list-disc"> {t("SmokeSensor.li5")}</li>
                  </ul>
                </p>
                <p className="FHead  -mt-4 ml-20 ">
                  {t("CentralACControlPanel.Heading")}
                  <ul className="mt-2 dParaF">
                    <li className=" my-1 list-disc">
                      {t("CentralACControlPanel.li1")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("CentralACControlPanel.li2")}
                    </li>
                    <li className="my-1  list-disc">
                      {t("CentralACControlPanel.li3")}
                    </li>
                  </ul>
                </p>
              </div>
              <div className="flex items-center justify-center">
                <img
                  src={smart2}
                  alt="smart"
                  className="  w-full object-cover object-center -z-20 "
                />
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="block md:hidden border border-default w-full h-full mt-5 "
          >
            <div className="flex flex-row">
              <div
                className=" bg-default rotate-180 w-1/3  flex items-center justify-center"
                style={{ writingMode: "vertical-rl" }}
              >
                <h1 className="dText  ">{t("features.title1")}</h1>
              </div>
              <div className="grid grid-cols-2  ml-4 mb-5 mr-4 ">
                <p className="FHeadM mt-4 ">
                  {t("WiFiSmartDoorLockS2.Heading")}
                  <ul className="mt-2 FParaM ">
                    <li className="my-0.5  list-disc">
                      {t("WiFiSmartDoorLockS2.li1")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {" "}
                      {t("WiFiSmartDoorLockS2.li2")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("WiFiSmartDoorLockS2.li3")}
                    </li>
                    <li className=" my-0.5 list-disc">
                      {t("WiFiSmartDoorLockS2.li4")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("WiFiSmartDoorLockS2.li5")}
                    </li>
                  </ul>
                </p>
                <p className="FHeadM mt-4 ml-4 ">
                  {t("MixSwitchSingleLive.Heading")}
                  <ul className="mt-2 FParaM">
                    <li className=" my-0.5 list-disc">
                      {" "}
                      {t("MixSwitchSingleLive.li1")}
                    </li>
                    <li className="my-0.5 list-disc">
                      {t("MixSwitchSingleLive.li2")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("MixSwitchSingleLive.li3")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("MixSwitchSingleLive.li4")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("MixSwitchSingleLive.li5")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("MixSwitchSingleLive.li6")}
                    </li>
                  </ul>
                </p>
                <p className="FHeadM mt-2 ">
                  {t("ZigbeeMiniHubVS20ZW.Heading")}
                  <ul className="mt-2 FParaM">
                    <li className=" my-0.5 list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li1")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li2")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li3")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li4")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("ZigbeeMiniHubVS20ZW.li5")}
                    </li>
                  </ul>
                </p>
                <p className="FHeadM mt-2 ml-4  ">
                  {t("MixPadMiniAllinone.Heading")}
                  <ul className="mt-2 FParaM">
                    <li className="my-0.5  list-disc">
                      {" "}
                      {t("MixPadMiniAllinone.li1")}
                    </li>
                    <li className=" my-0.5 list-disc">
                      {t("MixPadMiniAllinone.li2")}
                    </li>
                    <li className=" my-0.5 list-disc">
                      {t("MixPadMiniAllinone.li3")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("MixPadMiniAllinone.li4")}
                    </li>
                  </ul>
                </p>

                <p className="FHeadM mt-2 ">
                  {t("SmokeSensor.Heading")}
                  <ul className="mt-2 FParaM">
                    <li className=" my-0.5 list-disc">
                      {t("SmokeSensor.li1")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("SmokeSensor.li2")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("SmokeSensor.li3")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("SmokeSensor.li4")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {" "}
                      {t("SmokeSensor.li5")}
                    </li>
                  </ul>
                </p>
                <p className="FHeadM mt-2 ml-4 ">
                  {t("CentralACControlPanel.Heading")}
                  <ul className="mt-2 FParaM">
                    <li className=" my-0.5 list-disc">
                      {t("CentralACControlPanel.li1")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("CentralACControlPanel.li2")}
                    </li>
                    <li className="my-0.5  list-disc">
                      {t("CentralACControlPanel.li3")}
                    </li>
                  </ul>
                </p>
                <div className="flex items-center justify-center col-span-2">
                  <img
                    src={smart2}
                    alt="smart"
                    className="w-full object-cover object-center "
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1200"
            className="hidden md:block border border-default w-full h-full mt-28 mb-28"
          >
            <div className="flex flex-row">
              <div className="flex items-center justify-center">
                <img
                  src={dapt1}
                  alt="dapt"
                  className="h-3/6 w-full object-cover object-center"
                />
              </div>
              <div className="grid grid-cols-2 mr-72 ">
                <p className="FHead mt-4  mr-72 pr-20 ">
                  {t("advantages1.title")}
                  <p className="mt-2 dParaF pr-10 ">
                    {t("advantages1.content")}
                  </p>
                </p>
                <p className="FHead mt-4  ml-40 pr-32 mr-14">
                  {t("advantages2.title")}
                  <p className="mt-2 dParaF pr-10">
                    {t("advantages2.content")}
                  </p>
                </p>
                <p className="FHead mt-4  mr-32  ">
                  {t("advantages3.title")}
                  <p className="mt-2 dParaF pr-10 ">
                    {t("advantages3.content")}
                  </p>
                </p>
                <p className="FHead mt-4  ml-40 ">
                  {t("advantages4.title")}
                  <p className="mt-2 dParaF pr-10">
                    {t("advantages4.content")}
                  </p>
                </p>
                <p className="FHead  mr-32 pr-20 ">
                  {t("advantages5.title")}
                  <p className="mt-2 dParaF pr-5 mb-2  ">
                    {t("advantages5.content")}
                  </p>
                </p>
                <p className="FHead  ml-40 pr-32 ">
                  {t("advantages6.title")}
                  <p className="mt-2 dParaF pr-14">
                    {t("advantages6.content")}
                  </p>
                </p>
              </div>
              <div
                className=" bg-default rotate-240 w-1/3  flex items-center justify-center"
                style={{ writingMode: "vertical-rl" }}
              >
                <h1 className="dText  "> {t("features.title2")}</h1>
              </div>
            </div>
          </div>
          <div
            data-aos="fade-left"
            data-aos-duration="1200"
            className="block md:hidden border border-default w-full h-full mt-10 "
          >
            <div className="flex flex-row">
              <div className="grid grid-cols-2  ml-4  mr-4 ">
                <p className="FHeadM mt-4  ">
                  {t("advantages1.title")}
                  <p className="mt-2 FParaM">{t("advantages1.content")}</p>
                </p>
                <p className="FHeadM mt-4  ml-2 ">
                  {t("advantages2.title")}
                  <p className="mt-2 FParaM">{t("advantages2.content")}</p>
                </p>
                <p className="FHeadM mt-4   ">
                  {t("advantages3.title")}
                  <p className="mt-2 FParaM">{t("advantages3.content")}</p>
                </p>
                <p className="FHeadM mt-4 ml-2  ">
                  {t("advantages4.title")}
                  <p className="mt-2 FParaM ">{t("advantages4.content")}</p>
                </p>
                <p className="FHeadM mt-4  ">
                  {t("advantages5.title")}
                  <p className="mt-2 FParaM">{t("advantages5.content")}</p>
                </p>
                <p className="FHeadM mt-4  ml-2">
                  {t("advantages6.title")}
                  <p className="mt-2 FParaM ">{t("advantages6.content")}</p>
                </p>
                <div className="flex items-center justify-center col-span-2">
                  <img
                    src={dapt1}
                    alt="facade"
                    className="w-full object-cover object-center"
                  />
                </div>
              </div>
              <div
                className=" bg-default rotate-240 w-1/3  flex items-center justify-center"
                style={{ writingMode: "vertical-rl" }}
              >
                <h1 className="dText  "> {t("features.title2")}</h1>
              </div>
            </div>
          </div>

          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="hidden md:block border border-default w-full h-full mt-28 mb-28"
          >
            <div className="grid grid-cols-3">
              <div
                className=" bg-default rotate-180 w-1/3  flex items-center justify-center col-span-1"
                style={{ writingMode: "vertical-rl" }}
              >
                <h1 className="dText  "> {t("features.title3")}</h1>
              </div>
              <div className="grid grid-cols-2 -ml-52">
                <p className="FHead mt-4   ">
                  {t("insulation1.title")}
                  <p className="mt-2 dParaF">{t("insulation1.content")}</p>
                </p>
                <p className="FHead mt-4 ml-20 ">
                  {t("insulation2.title")}
                  <p className="mt-2 dParaF">{t("insulation2.content")}</p>
                </p>
                <p className="FHead mt-4   ">
                  {t("insulation3.title")}
                  <p className="mt-2 dParaF">{t("insulation3.content")}</p>
                </p>
                <p className="FHead mt-4  ml-20 pr-10 ">
                  {t("insulation4.title")}
                  <p className="mt-2 dParaF pr-10">
                    {t("insulation4.content")}
                  </p>
                </p>
                <p className="FHead mt-4   ">
                  {t("insulation5.title")}
                  <p className="mt-2 dParaF">{t("insulation5.content")}</p>
                </p>
                <p className="FHead mt-4  ml-20 pr-20 ">
                  {t("insulation6.title")}
                  <p className="mt-2 dParaF ">{t("insulation6.content")}</p>
                </p>
              </div>
              <div className="flex items-center justify-cente">
                <img
                  src={ytong}
                  alt="smart"
                  className=" h-3/6 w-full object-cover object-center"
                />
              </div>
            </div>
          </div>
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="block md:hidden border border-default w-full h-full mt-10 mb-10"
          >
            <div className="flex flex-row">
              <div
                className=" bg-default rotate-180 w-1/3  flex items-center justify-center"
                style={{ writingMode: "vertical-rl" }}
              >
                <h1 className="dText  "> {t("features.title3")}</h1>
              </div>
              <div className="grid grid-cols-2  ml-4  mr-4 ">
                <p className="FHeadM mt-4 ">
                  {t("insulation1.title")}
                  <p className="mt-2 FParaM">{t("insulation1.content")}</p>
                </p>
                <p className="FHeadM mt-4  ml-2 ">
                  {t("insulation2.title")}
                  <p className="mt-2 FParaM">{t("insulation2.content")}</p>
                </p>
                <p className="FHeadM mt-4   ">
                  {t("insulation3.title")}
                  <p className="mt-2 FParaM">{t("insulation3.content")}</p>
                </p>
                <p className="FHeadM mt-4 ml-2  ">
                  {t("insulation4.title")}
                  <p className="mt-2 FParaM ">{t("insulation4.content")}</p>
                </p>
                <p className="FHeadM mt-4  ">
                  {t("insulation5.title")}
                  <p className="mt-2 FParaM">{t("insulation5.content")}</p>
                </p>
                <p className="FHeadM mt-4  ml-4 ">
                  {t("insulation6.title")}
                  <p className="mt-2 FParaM ">{t("insulation6.content")}</p>
                </p>
                <div className="flex items-center justify-center col-span-2">
                  <img
                    src={ytong}
                    alt="ytong"
                    className="w-full object-cover object-center"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Special;
