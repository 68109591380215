import { useEffect } from "react";
import "./explore.css"; // You can keep this if needed
import heroT from "../../media/heroT.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Explore() {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation("home");

  return (
    <div className="font-monsterrat mb-5 md:mb-10 ">
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="border-b border-default ml-8 md:ml-44 mr-8 md:mr-44"
      >
        <h1 className="head text-center "> {t("explore.title")}</h1>
        <h1 className="parag text-center mb-5 md:mb-10 mt-4">
        {t("explore.description")}
        </h1>
      </div>
      <div className="md:grid grid-cols-2 mb-2 md:mb-10 ">
        <div
          data-aos="fade-right"
          data-aos-duration="1200"
          className="ml-8 md:ml-44 mr-8 md:mr-0"
        >
          <img src={heroT} alt="Hero Image" className=" h-full w-full" />
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className=" flex flex-wrap justify-center md:grid grid-cols-2  md:ml-6  mt-10 "
        >
          <div className="eclipse  mb-3 md:mb-5 flex flex-col items-center justify-center  ">
            <span className="text1">5</span>
            <span className="text2 mt-4">{t("explore.floors")}</span>
          </div>
          <div className="eclipse ml-8 md:-ml-14 flex flex-col items-center justify-center  ">
            <span className="text1">20</span>
            <span className="text2 mt-4">{t("explore.apartments")}</span>
          </div>
          <div className="eclipse flex flex-col items-center justify-center ">
            <span className="text1">19 m</span>
            <span className="text2 mt-4">{t("explore.length")}</span>
          </div>
          <div className="eclipse ml-8 md:-ml-14 flex flex-col items-center justify-center  ">
            <span className="text1">14 m</span>
            <span className="text2 mt-4">{t("explore.width")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Explore;
