import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { useTranslation } from "react-i18next";
import "./deluxe.css"; // Include your own styles here
import ytong from "../../media/ytong.png";
import dapt1 from "../../media/dapt1.png";
import smart2 from "../../media/smart2.png";
function Deluxe() {
  const [activeContent, setActiveContent] = useState("Smart Home");
  const { t } = useTranslation("home");

  const contentMap = {
    "Smart Home": {
      image: smart2,
      Htitle: t("Smart Home.Advantages.Htitle"),
      title: t("Smart Home.Advantages.title"),
      text: t("Smart Home.Advantages.text"),
      style: {
        h1: "bg-default rotate-180 w-1/3 flex items-center justify-center",
        h1Advantage: "dHead",
        paraAdvantage: "dPara mt-4 ",
        img: "dImg",
      },
    },
    Insulation: {
      image: ytong,
      Htitle: t("Insulation.Ytong Bricks.Htitle"),
      title: t("Insulation.Ytong Bricks.title"),
      text: t("Insulation.Ytong Bricks.text"),
      style: {
        h1: "bg-default rotate-240 w-1/3 flex items-center justify-center",
        h1Advantage: "dHead",
        paraAdvantage: "dPara mt-4",
        img: "dImg ",
      },
    },
    Facade: {
      image: dapt1,
      Htitle: t("Facade.New Tech Walls.Htitle"),
      title: t("Facade.New Tech Walls.title"),
      text: t("Facade.New Tech Walls.text"),
      style: {
        layout: "flex-row", // Flex layout direction
        content: "flex flex-col justify-center mt-10 ", // Flex properties for content container
        h1: "bg-default rotate-180 w-1/3 flex items-center justify-center", // Flex properties for vertical title container
        h1Advantage: "dHead",
        paraAdvantage: "dPara mt-4",
        img: "dImg",
      },
    },
  };
  const mobileMap = {
    "Smart Home": {
      image: smart2,
      Htitle: t("Smart Home.Advantages.Htitle"),
      title: t("Smart Home.Advantages.title"),
      text: t("Smart Home.Advantages.text"),
      style: {
        h1: "bg-default rotate-180 w-1/3 flex items-center justify-center",
        img: "dImg",
      },
    },
    Facade: {
      image: dapt1,
      Htitle: t("Facade.New Tech Walls.Htitle"),
      title: t("Facade.New Tech Walls.title"),
      text: t("FacadeM.New Tech Walls.text"),
      style: {
        h1: "bg-default rotate-180 w-1/3 flex items-center justify-center", // Flex properties for vertical title container
      },
    },
    Insulation: {
      image: ytong,
      Htitle: t("Insulation.Ytong Bricks.Htitle"),
      title: t("Insulation.Ytong Bricks.title"),
      text: t("Insulation.Ytong Bricks.text"),
      style: {
        h1: "bg-default rotate-240 w-1/3 flex items-center justify-center",
      },
    },
  };
  return (
    <div className="mt-0 md:mt-6">
      <div className="border-t border-default  ml-8 md:ml-44 mr-8 md:mr-44">
        <h1 className="headD text-center mt-10 md:mt-12 mb-8 md:mb-14 ">
          {t("advantage.title")}
        </h1>
        <div className="grid grid-cols-3">
          {Object.keys(contentMap).map((content) => (
            <button
              key={content}
              className={`typeBtnD ${
                activeContent === content
                  ? "border-b-2 border-default text-default mr-0 md:mr-10 ml-0 md:ml-10 py-2"
                  : "bg-background text-defaultOP "
              }`}
              onClick={() => setActiveContent(content)}
            >
      {t(`categories.${content}`)}
            </button>
          ))}
        </div>
        {Object.keys(contentMap).map((content) => (
          <CSSTransition
            key={content}
            in={activeContent === content}
            timeout={400}
            classNames="fade"
            unmountOnExit
          >
            <div
              className={`border border-default w-full h-full mt-10 md:mt-10 mb-10 md:mb-0 hidden md:block ${
                activeContent === content ? "" : "hidden"
              }`}
            >
              <div className="flex flex-row">
                {content === "Insulation" || content === "Sanitary" ? (
                  <div className="flex items-center justify-center ">
                    <img
                      src={contentMap[content].image}
                      alt={contentMap[content].title}
                      className={contentMap[content].style.img}
                    />
                  </div>
                ) : (
                  <div
                    className={contentMap[content].style.h1}
                    style={{ writingMode: "vertical-rl" }}
                  >
                    <h1 className="dText">{contentMap[content].Htitle}</h1>
                  </div>
                )}
                <div
                  className={
                    content === "Insulation" || content === "Sanitary"
                      ? "md:flex flex-col justify-center md:mr-24"
                      : "md:flex flex-col justify-center -ml-14 md:ml-24  mr-20 md:mr-0"
                  }
                >
                  <h1 className={contentMap[content].style.h1Advantage}>
                    {contentMap[content].title}
                  </h1>
                  <p
                    className={
                      content === "Insulation" || content === "Sanitary"
                        ? "dPara mt-5  "
                        : contentMap[content].style.paraAdvantage
                    }
                  >
                    {contentMap[content].text}
                  </p>
                  <a
                    href="/features"
                    className="text-center text-default text-xl bg-background hover:bg-default hover:text-white border border-defaultOP mt-2 py-2 px-4 "
                  >
                    {t("advantage.read")}
                  </a>
                </div>
                {content === "Insulation" || content === "Sanitary" ? (
                  <div
                    className={contentMap[content].style.h1}
                    style={{ writingMode: "vertical-rl" }}
                  >
                    <h1 className="dText">{contentMap[content].Htitle}</h1>
                  </div>
                ) : (
                  <div className="mt-10 md:mt-0 md:flex items-center justify-center">
                    <img
                      src={contentMap[content].image}
                      alt={contentMap[content].title}
                      className={contentMap[content].style.img}
                    />
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>
        ))}
        {Object.keys(mobileMap).map((content) => (
          <CSSTransition
            key={content}
            in={activeContent === content}
            timeout={400}
            classNames="fade"
            unmountOnExit
          >
            <div
              className={`border border-default w-full h-full mt-10  mb-10  block md:hidden ${
                activeContent === content ? "" : "hidden"
              }`}
            >
              <div className="flex flex-row">
                {content === "Insulation" || content === "Sanitary" ? (
                  <div className="flex items-center justify-center ">
                    <img
                      src={mobileMap[content].image}
                      alt={mobileMap[content].title}
                      className={mobileMap[content].style.img}
                    />
                  </div>
                ) : (
                  <div
                    className={mobileMap[content].style.h1}
                    style={{ writingMode: "vertical-rl" }}
                  >
                    <h1 className="dText">{mobileMap[content].Htitle}</h1>
                  </div>
                )}
                <div
                  className={
                    content === "Insulation" || content === "Sanitary"
                      ? "flex flex-col justify-center"
                      : "flex flex-col justify-center "
                  }
                >
                  <h1
                    className={
                      content === "Insulation" || content === "Sanitary"
                        ? "dHeadM mt-4"
                        : "dHeadM ml-4 mt-4 "
                    }
                  >
                    {mobileMap[content].title}
                  </h1>
                  <p
                    className={
                      content === "Insulation" || content === "Sanitary"
                        ? " dParaM mt-2  mr-4 "
                        : "dParaM mt-2 ml-4"
                    }
                  >
                    {mobileMap[content].text}
                  </p>
                  <a
                    href="/features"
                    className={
                      content === "Insulation" || content === "Sanitary"
                        ? " text-center text-default text-xs bg-background hover:bg-default hover:text-white border border-defaultOP mt-2 py-1 mr-4 mb-2  "
                        : "text-center text-default text-xs bg-background hover:bg-default hover:text-white border border-defaultOP mt-2 py-1 ml-4 mb-2"
                    }
                  >
                    {t("advantage.read")}
                  </a>
                </div>
                {content === "Insulation" || content === "Sanitary" ? (
                  <div
                    className={mobileMap[content].style.h1}
                    style={{ writingMode: "vertical-rl" }}
                  >
                    <h1 className="dText">{mobileMap[content].Htitle}</h1>
                  </div>
                ) : (
                  <div className="flex items-center justify-center">
                    <img
                      src={mobileMap[content].image}
                      alt={mobileMap[content].title}
                      className={mobileMap[content].style.img}
                    />
                  </div>
                )}
              </div>
            </div>
          </CSSTransition>
        ))}
      </div>
    </div>
  );
}

export default Deluxe;
