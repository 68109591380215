import React, { useEffect } from "react";
import "./location.css";
import location from "../../media/location.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Location() {
  useEffect(() => {
    AOS.init();
  }, []);
  const { t } = useTranslation("contact");

  return (
    <div className=" bg-background">
      <div className="ml-8 md:ml-44 mr-8 md:mr-44 ">
        <h1 className="headFe text-left mt-10 md:mt-10 mb-10 md:mb-10 border-b-2 border-defaultOP">
          {t("contact.title")}
        </h1>
        <h1
          data-aos="fade-up"
          data-aos-duration="1200"
          className="titleFe text-center mt-8 md:mt-10 "
        >
          {t("contact.subTitle")}
        </h1>
        <h1
          data-aos="fade-up"
          data-aos-duration="1200"
          className="paraFe text-center mt-6 mb-16 md:mb-1 "
        >
          {t("contact.title1")}
        </h1>
      </div>
      <div className=" md:grid grid-cols-2 mt-10 md:mt-20 mb-10 md:mb-32 mr-8 md:mr-44 ml-8 md:ml-44">
        <div
          data-aos="fade-right"
          data-aos-duration="1200"
          className="relative"
        >
          <img src={location} alt="location" className="Limage" />
          <a
            className=""
            href="https://maps.app.goo.gl/GUjkhNjmeRjq9vmM6"
            target={"_blank"}
          >
            <div className="absolute -bottom-10 -right-2 lg:-right-8 z-20">
              <div className="relative hover:scale-105">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-20 md:w-96 h-40 "
                  viewBox="0 0 240 242"
                  fill="none"
                >
                  <path
                    d="M238.5 121C238.5 187.01 185.434 240.5 120 240.5C54.566 240.5 1.5 187.01 1.5 121C1.5 54.9902 54.566 1.5 120 1.5C185.434 1.5 238.5 54.9902 238.5 121Z"
                    fill="#170F14"
                    stroke="#C1A875"
                    strokeWidth="2"
                  />
                </svg>
                <h1 className="absolute  inset-0 flex items-center justify-center text-white text-xs md:text-lg">
                  {t("contact.button")}
                </h1>
              </div>
            </div>
          </a>
        </div>
        <h1
          data-aos="fade-left"
          data-aos-duration="1200"
          className="Lpara text-left mt-14 md:mt-6 my-4"
        >
          {t("contact.text")}
        </h1>
      </div>
      <div className=" md:flex justify-between ml-8 md:ml-44  mr-8 md:mr-44   mt-10 md:mt-40 mb-0 md:mb-10">
        <div
          data-aos="fade-up"
          data-aos-duration="1200"
          className=" border-b border-default "
        >
          <h1 className="Phead"> {t("contact.title2")}</h1>
          <p className="Ptext mt-4 mb-2 md:mb-0 ">
            <a href="tel:+38970389779" className="hover:text-bgop">
              +38970389779{" "}
            </a>{" "}
            /{" "}
            <a href="tel:+38976420420" className="hover:text-bgop">
              +38976420420{" "}
            </a>
          </p>
        </div>
        <div
          data-aos="fade-up"
          data-aos-duration="1200"
          className=" border-b border-default mt-4 md:mt-0 mb-4 md:mb-0 ml-0 md:ml-20 "
        >
          <h1 className="Phead"> {t("contact.title3")}</h1>
          <p className="Ptext mt-4 mb-2">Info@deluxe-home.mk</p>
        </div>
        <a
          data-aos="fade-left"
          data-aos-duration="1200"
          href="mailto:Info@deluxe-home.mk"
          className="hidden md:block text-center text-default text-xl bg-background hover:bg-default hover:text-white border border-defaultOP mt-10 md:-mt-10    py-2 px-4 md:px-2  md:py-10     "
        >
        {t("contact.title4")}
        </a>
      </div>
      <a
        data-aos="fade-up"
        data-aos-duration="1000"
        href="mailto:Info@deluxe-home.mk"
        className="block md:hidden text-center text-default text-xl bg-background hover:bg-default hover:text-white border border-defaultOP    py-2 px-4 mt-5   mr-8 ml-8 mb-8 "
      >
        {t("contact.title4")}
      </a>
      <div className="border-b border-default ml-8 md:ml-44 mr-8 md:mr-44"></div>
    </div>
  );
}
export default Location;
