import React, { useEffect } from "react";
import Gallery from "../home/Gallery";
import "./agallery.css";
import AOS from "aos";
import "aos/dist/aos.css";

function Agallery() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="border-b border-default mr-8 md:mr-44 ml-8 md:ml-44 ">
        {" "}
      </div>
      <Gallery />
    </>
  );
}

export default Agallery;
