import React from "react";
import Hero from "./Hero";
import Explore from "./Explore";
import Apartments from "./Aparments";
import Gallery from "./Gallery";
import Deluxe from "./Deluxe";
import Location from "./Location";
import Footer from "../Footer";
function Home() {
  return (
    <div className=" bg-background">
      <Hero />
      <Explore />
      <Apartments />
      <Gallery />
      <Deluxe />
      <Location />
      <Footer />
    </div>
  );
}
export default Home;
