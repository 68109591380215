// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enHome from './i18n/en/home.json';
import mkHome from './i18n/mk/home.json';
import enFeatures from "./i18n/en/features.json"
import mkFeatures from "./i18n/mk/features.json"
import enAbout from "./i18n/en/about.json"
import mkAbout from "./i18n/mk/about.json"
import enContact from "./i18n/en/contact.json"
import mkContact from "./i18n/mk/contact.json"
const resources = {
  en: {
    home: enHome,
    features: enFeatures,
    about:enAbout,
    contact:enContact
  },
  mk: {
    home: mkHome,
    features: mkFeatures,
    about: mkAbout,
    contact: mkContact
  },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en', // fallback language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
