import React, { useState, useEffect } from "react";
import "./apt.css"; // You can keep this if needed
import InteriorA from "../../media/InteriorA.png";
import InteriorB from "../../media/InteriorB.png";
import InteriorC from "../../media/InteriorC.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";

function Apartments() {
  const [annots, setAnnots] = useState([]);

  const { t } = useTranslation("home");

  useEffect(() => {
    AOS.init();
  }, []);
  const [activeType, setActiveType] = useState("Type A");

  const contentMap = {
    "Type A": {
      image: InteriorA,
      roomSize: "52m²",
      living: "26m2",
      first: "7m2",
      second: "9m2",
      bathroom: "6m2",
      balcony: "4m2",
    },
    "Type B": {
      image: InteriorB,
      roomSize: "54m²",
      living: "28m2",
      first: "7m2",
      second: "9m2",
      bathroom: "6m2",
      balcony: "4m2",
    },
    "Type C": {
      image: InteriorC,
      roomSize: "56m²",
      living: "29m2",
      first: "7m2",
      second: "10m2",
      bathroom: "6m2",
      balcony: "4m2",
    },
  };
  return (
    <div className="font-monsterrat mb-6 md:mb-20 border-y border-default ml-8 md:ml-44 mr-8 md:mr-44 ">
      <h1
        data-aos="fade-up"
        data-aos-duration="1000"
        className="headApt text-center mt-4 md:mt-4 mb-4 md:mb-4 "
      >
        {t("deluxe.title")}
      </h1>
      <div
        data-aos="fade-up"
        data-aos-duration="1000"
        className="grid grid-cols-3  "
      >
        {Object.keys(contentMap).map((type) => (
          <button
            key={type}
            className={`typeBtn ${
              activeType === type
                ? "border-b-2 border-default text-default mr-0 md:mr-10 ml-0 md:ml-10 py-2"
                : "bg-background text-defaultOP "
            }`}
            onClick={() => setActiveType(type)}
          >
            {t(`types.${type}`)}
          </button>
        ))}
      </div>

      <div className="md:grid grid-cols-3 mb-10 md:mb-10 mt-4 md:mt-10">
        <div
          data-aos="fade-right"
          data-aos-duration="1200"
          className="col-span-2"
        >
          <div className="flex justify-center  mt-10 md:mt-0 ">
            <img
              className="sm:ml-2 ml-10 bg-cover object-cover object-center md:max-h-fit sm:h-fit h-full sm:w-full w-auto sm:mr-0 mr-12"
              src={contentMap[activeType].image}
              alt={contentMap[activeType]}
            ></img>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className=" col-span-1 ml-8 md:ml-14 mr-8 md:mr-0 mt-2 md:mt-4"
        >
          <div className="grid grid-rows-3 border-y border-default ">
            <div className="grid grid-cols-2 border-b border-default">
              <h1 className="aptx1 text-left my-4">
                {contentMap[activeType].roomSize}
              </h1>
              <h1 className="aptx2 text-center my-4">
                {" "}
                {t("deluxe.apartment")}
              </h1>
            </div>
            <div className="grid grid-cols-2 border-b border-default ">
              <h1 className="aptx1 text-left my-4">
                {contentMap[activeType].living}
              </h1>
              <h1 className="aptx2 text-left my-4"> {t("deluxe.living")}</h1>
            </div>
            <div className="grid grid-cols-2 border-b border-default ">
              <h1 className="aptx1 text-left my-4">
                {contentMap[activeType].first}
              </h1>
              <h1 className="aptx2 text-left my-4"> {t("deluxe.first")}</h1>
            </div>
            <div className="grid grid-cols-2 border-b border-default ">
              <h1 className="aptx1 text-left my-4">
                {contentMap[activeType].second}
              </h1>
              <h1 className="aptx2 text-left my-4">{t("deluxe.second")}</h1>
            </div>
            <div className="grid grid-cols-2 border-b border-default ">
              <h1 className="aptx1 text-left my-4">
                {contentMap[activeType].bathroom}
              </h1>
              <h1 className="aptx2 text-left my-4">{t("deluxe.bathroom")}</h1>
            </div>
            <div className="grid grid-cols-2 border-b border-default ">
              <h1 className="aptx1 text-left my-4">
                {contentMap[activeType].balcony}
              </h1>
              <h1 className="aptx2 text-left my-4">{t("deluxe.balcony")}</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Apartments;
